<template>
  <div class="content">
    <div class="top-btn-group">
      <!--            <el-button size="small" @click="handleDocumentList" type="primary">导出列表</el-button>-->
      <el-button
        size="small"
        type="primary"
        @click="toMemberIndex"
        :loading="buttonLoading"
        >查看测试账号</el-button
      >
    </div>
    <div>
      <SourceScreen :filterForm="filterForm" :uploadFilter="ok"></SourceScreen>
    </div>
    <div v-loading="loading" style="margin-top: 20px">
      <el-table
        :data="list"
        stripe
        @sort-change="sortChange"
        class="thead-light"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          prop="create_time"
          min-width="160px"
          label="操作时间"
          sortable
        ></el-table-column>
        <el-table-column
          prop="admin_user_name"
          min-width="110px"
          label="操作人"
        ></el-table-column>
        <el-table-column
          prop="module"
          min-width="100px"
          label="操作模块"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          min-width="320px"
          prop="content"
          label="操作内容"
        >
          <!--                    <template>-->
          <!--                    -->
          <!--                    </template>-->
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    ></Pagination>
  </div>
</template>

<script>
import SourceScreen from '../components/SourceScreen'
import Pagination from '@/base/components/Default/Pagination'
import { getIndex } from '../api/admin-user-log'
import { getTester } from '@/modules/system-admin/api/admin-user-log'
export default {
  components: {
    SourceScreen,
    Pagination,
  },
  data() {
    return {
      filterForm: {
        module: '',
        create_start_time: -1,
        create_end_time: -1,
        nickname: '',
        page_size: 15,
        is_desc: 1,
        export_ids: [],
      },
      pageData: {},
      list: [],
      loading: false, //加载
      total: 0,
      buttonLoading: false,
    }
  },
  created() {
    this.updataIndex(this.filterForm)
  },
  methods: {
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.filterForm.export_ids = []
      } else {
        this.filterForm.export_ids = []
        // 筛选id
        selection.forEach((item) => {
          this.filterForm.export_ids.push(item.id)
        })
      }
    },
    //页面切换
    changPage(data) {
      this.filterForm.page_size = data.page_size
      this.updataIndex({ ...this.filterForm, ...data })
    },
    updataIndex(requestData) {
      this.loading = true
      this.pageData = {}
      getIndex(requestData)
        .then((res) => {
          this.list = res.data.data
          this.pageData = res.data
          this.loading = false
        })
        .catch((err) => {})
    },
    ok(e) {
      this.filterForm = { ...e, page: 1 }
      this.updataIndex(this.filterForm)
    },
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === 'ascending'
        ? 0
        : 1
      this.filterForm.order_by = column.prop
      this.updataIndex(this.filterForm)
    },
    toMemberIndex() {
      this.buttonLoading = true
      getTester()
        .then(({ data: userId }) => {
          const data = {}
          data.id = userId
          data.type = 'formal'
          data.status = 1
          this.$router.push({
            name: 'EditMember',
            params: { data: this.$enCode(data) },
          })
          this.buttonLoading = false
        })
        .catch(() => {
          this.buttonLoading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
.content {
  .top-btn-group {
    margin-bottom: $space;
  }
}
</style>
