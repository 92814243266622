<template>
  <LayoutFilter
    :onFilter="ok"
    :collapseHeight="120"
    :toggleCollapseEnable="false"
    :onExport="onExport"
    :onReset="resetForm"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="logFilterForm"
      label-width="55px"
      size="medium"
      class="filter-from"
    >
      <div class="filter-item">
        <DatePeriodPicker
          label="时间："
          start-prop="create_start_time"
          :start-time.sync="form.create_start_time"
          end-prop="create_end_time"
          :end-time.sync="form.create_end_time"
        />
      </div>
      <div class="filter-item">
        <el-form-item label="操作人：" prop="nickname">
          <el-input
            v-model="form.nickname"
            placeholder="请输入管理员名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="操作模块：" label-width="70px" prop="module">
          <el-select v-model="form.module" placeholder="模块">
            <el-option
              v-for="(item, index) in modules"
              :label="item.name"
              :key="index"
              :value="item.belong_feature"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import { getModules, logDocument } from '../api/admin-user-log'
import { documentArticle } from '../api/admin-user-log.js'

export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },

    filterForm: {
      type: Object,
    },
    // onExport:{
    //     type: Function,
    // }
  },
  data() {
    return {
      rules: {},
      modules: [],
    }
  },

  methods: {
    onExport() {
      if (!this.form.export_ids.length) {
        this.$message.info('请选择数据导出!')
        return
      }
      const postData = { ...this.form }
      return logDocument(postData).catch((err) => {})
    },
    //重置表单
    resetForm() {
      this.$refs['logFilterForm'].resetFields()
      this.uploadFilter(this.form)
    },
    //筛选
    ok() {
      this.uploadFilter(this.form)
    },
  },
  created() {
    getModules()
      .then((res) => {
        this.modules = res.data
      })
      .catch((err) => {})
  },
  computed: {
    form: {
      get() {
        return this.filterForm
      },
      set(val) {
        this.$emit('updateFilter', val)
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
}
</script>
